import { ENV, getEnv } from '@shared/utils';
import { BrowserType, useBrowserType } from './use-browser-type';
const env = getEnv();
export enum VideoExtension {
  MP4 = 'mp4',
  WEBM = 'webm',
  MOV = 'mov'
}

const ED_VIDEOS_VARIATIONS = ['A', 'B', 'C', 'D', 'E'];

function getVideosEnv() {
  if (env === ENV.PROD) {
    return '.';
  }
  return '.dev.';
}
// these variables are constants and should be outside the function scope
const extVideoTypeMap: Record<VideoExtension, string> = {
  [VideoExtension.MP4]: 'video/mp4',
  [VideoExtension.WEBM]: 'video/webm',
  [VideoExtension.MOV]: 'video/quicktime'
};
const videosEnv = getVideosEnv();
const videosPrefixUrl = `https://files${videosEnv}lausd.iap.allhere.co/videos`;

const isValidVideoSource = async (source: string) => {
  return new Promise((resolve) => {
    fetch(source)
      .then((result) => {
        return resolve(result.status === 200);
      })
      .catch(() => {
        return resolve(false);
      });
  });
};

export const useVideoSource = () => {
  const browserType = useBrowserType();
  const validVideoExtensions =
    browserType === BrowserType.SAFARI ? [VideoExtension.MP4] : [VideoExtension.WEBM];

  const getVideoSources = async (folder: string, language: string) => {
    const sources: Node[] = [];
    const randomVideoIndex = Math.floor(Math.random() * ED_VIDEOS_VARIATIONS.length);
    const videoVariation = ED_VIDEOS_VARIATIONS[randomVideoIndex];
    const videosPath = `${videosPrefixUrl}/${folder}`;
    for (const ext of validVideoExtensions) {
      const el = document.createElement('source');
      el.setAttribute('type', extVideoTypeMap[ext]);
      const userLngSrc = `${videosPath}/${videoVariation}/${language}.${ext}`;
      if (await isValidVideoSource(userLngSrc)) {
        el.setAttribute('src', userLngSrc);
      } else {
        el.setAttribute('src', `${videosPath}/A/en.${ext}`);
      }
      sources.push(el);
    }
    return sources;
  };

  return {
    getVideoSources
  };
};
